<template>
  <main
    class="
      bg-transparent
      xl:px-6
      lg:px-6
      md:px-8
      px-3
      pb-32
      text-legend-gray
      dark:text-border-color
    "
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <section class="w-full">
      <main>
        <section class="capitalize flex items-center mb-6">
          <home-icon
            size="1.3x"
            class="custom-class text-primary-color cursor-pointer"
            @click="$router.push({ name: 'Purchases' })"
          ></home-icon
          ><span class="mx-2">/</span>
          <span
            class="capitalize text-primary-color cursor-pointer"
            @click="
              $router.push({
                name: 'PurchaseCategory',
                params: {
                  purchaseCategory: $route.params.purchaseCategory,
                },
              })
            "
          >
            {{
              categoryId ? $route.params.purchaseCategory : 'All stores'
            }}</span
          >
          <span class="mx-2">/</span>
          <span class="capitalize"> {{ $route.params.merchant }}</span>
        </section>
        <section class="w-full flex items-center">
          <div class="w-full flex xl:flex-row lg:flex-row md:flex-col flex-col">
            <dashboard-card
              class="bg-primary-color mr-6"
              @toggleBalance="toggleWalletBalance"
              :show-balance="showWalletBalance"
              :data-set="'balance'"
            >
              <img
                src="@/assets/images/subscription-icon-light.svg"
                alt="go to subscriptions"
                slot="icon"
                class="w-8"
              />
              <span slot="title">Wallet Balance</span>
              <span slot="action" class="text-primary-color">Top up</span>
              <span slot="amount" class="inline-block w-14">
                <span v-if="showWalletBalance" class="w-full"
                  >₦{{ walletBalance }}</span
                >
                <img
                  v-else
                  src="@/assets/images/encrypt.svg"
                  alt="wallet balance"
                  class="w-full"
                />
              </span>
            </dashboard-card>
            <dashboard-card
              class="bg-primary-green dark:bg-dark-mode-green"
              @toggleBalance="toggleRewardPoints"
              :show-balance="showRewardPoints"
              :data-set="'rewards'"
            >
              <img
                src="@/assets/images/reward-pack.svg"
                alt="go to subscriptions"
                slot="icon"
              />
              <span slot="title">Total Reward points</span>
              <span
                slot="action"
                class="text-primary-green dark:text-dark-mode-green"
                >Redeem</span
              >
              <span slot="amount" class="inline-block w-14">
                <span v-if="showRewardPoints" class="full">{{
                  rewardPoints
                }}</span>
                <img
                  v-if="!showRewardPoints"
                  src="@/assets/images/encrypt.svg"
                  alt="reward points"
                  class="w-full"
                />
              </span>
            </dashboard-card>
          </div>
        </section>
        <section class="flex items-start justify-between my-20 relative">
          <div class="flex items-center">
            <div>
              <div
                class="
                  xl:w-20
                  lg:w-20
                  md:w-20
                  w-14
                  xl:h-20
                  lg:h-20
                  md:h-20
                  h-14
                  mr-3
                  rounded-full
                  bg-gray-200
                "
              >
                <img
                  v-if="currentMerchant && currentMerchant.image"
                  :src="`${url}/${currentMerchant.image}`"
                  :alt="currentMerchant.name"
                  class="w-full h-full rounded-full object-cover"
                />
              </div>
            </div>
            <div>
              <h1
                class="
                  font-bold
                  xl:text-xl
                  lg:text-xl
                  md:text-lg
                  text-base text-black
                  dark:text-border-color
                "
              >
                {{ currentMerchant.name }}
              </h1>
              <div
                class="
                  text-legend-gray text-sm
                  xl:w-96
                  lg:w-96
                  md:w-96
                  w-11/12
                  mt-1
                  dark:text-border-color
                  line-clamp-1
                "
              >
                {{ currentMerchant.location }}
              </div>
            </div>
          </div>
          <button
            class="
              bg-primary-color
              text-white text-sm
              rounded-md
              py-2
              xl:px-6
              lg:px-6
              md:px-6
              px-3
              xl:inline-flex
              lg:inline-flex
              md:inline-flex
              absolute
              xl:-top-0
              lg:-top-0
              md:-top-0
              -top-10
              right-0
            "
            @click="makingDirectPayment = true"
          >
            Direct Payment
          </button>
        </section>
        <section
          class="
            w-80
            flex
            items-center
            border border-border-color
            rounded-lg
            p-2
            relative
          "
        >
          <img
            src="@/assets/images/search.svg"
            alt="search products"
            class="flex-shrink-0"
          />
          <input
            v-debounce:1000="search"
            v-model="searchTerm"
            type="text"
            class="w-full focus:outline-none bg-transparent px-2"
            placeholder="Search"
          />
          <x-icon
            v-if="searchTerm.length"
            size="1.5x"
            class="custom-class cursor-pointer"
            @click="fetchProductsByMerchant(1)"
          ></x-icon>
        </section>
      </main>
    </section>
    <!-- Loading spinner -->
    <LoadingSpinner v-if="loading && !products" />
    <p
      v-if="!loading && products && !products.length"
      class="text-center py-12 font-semibold"
    >
      No products available yet.
    </p>
    <section
      v-if="!loading && products && products.length"
      class="
        flex
        xl:flex-row
        lg:flex-row
        md:flex-col
        flex-col
        items-start
        mt-12
      "
    >
      <ul
        class="
          xl:w-1/6
          lg:w-1/6
          md:w-full
          w-full
          xl:h-auto
          lg:h-auto
          md:h-32
          h-32
          overflow-y-scroll
          overscroll-y-contain
          flex-shrink-0
          xl:mt-6
          lg:mt-6
          md:mt-0
          mt-0
          p-6
          pb-6
          mr-6
          mb-12
          capitalize
          dark:bg-dark-mode-primary
          bg-white
        "
      >
        <li v-if="!loading && !categories.length" class="py-2">
          Category coming soon
        </li>
        <li
          v-for="category in categories"
          :key="category.id"
          class="pb-4 cursor-pointer"
          @click="getProductsByCategory(category)"
        >
          {{ category.name }}
        </li>
      </ul>
      <main class="w-full">
        <h1
          class="
            text-legend-black
            dark:text-border-color
            text-lg
            font-bold
            mb-4
          "
        >
          {{ categoryName }}
        </h1>
        <ul
          class="
            grid
            xl:grid-cols-4
            lg:grid-cols-3
            md:grid-cols-3
            grid-cols-2
            place-content-between
            xl:gap-6
            lg:gap-6
            md:gap-5
            gap-4
          "
        >
          <li
            v-for="product in products"
            :key="product.id"
            class="p-2 bg-white dark:bg-dark-mode-primary rounded pb-8"
          >
            <div class="w-full h-32 bg-gray-200">
              <img
                v-if="product.images && product.images.length"
                :src="`${url}/${product.images[0].path}`"
                :alt="product.name"
                class="w-full h-full object-cover"
              />
              <img
                v-else
                :src="getImgUrl()"
                :alt="product.name"
                class="w-full h-full object-cover"
              />
            </div>
            <h1 class="font-bold mt-2 mb-3">
              <router-link
                :to="{
                  name: 'PurchaseView',
                  params: {
                    purchaseCategory: $route.params.purchaseCategory,
                    merchant: $route.params.merchant,
                    product: product.name,
                  },
                  query: { prd___qnty: product.id },
                }"
                :title="product.name"
                class="font-semibold block my-2 capitalize line-clamp-1"
              >
                {{ product.name }}
              </router-link>
            </h1>
            <p class="product-description text-sm leading-relaxed line-clamp-2">
              {{ product.description }}
            </p>
            <p
              class="
                text-xl
                font-bold
                text-legend-black
                dark:text-border-color
                mt-4
              "
            >
              ₦{{ product.price | moneyFormat }}
            </p>
          </li>
        </ul>
        <PaginationComponent
          v-if="products && products.length"
          :result="result"
          @fetchByPage="fetchProductsByMerchant"
        />
      </main>
    </section>
    <custom-modal v-if="makingDirectPayment">
      <DirectPayment
        slot="cart"
        :staffList="staffList"
        @closeModal="makingDirectPayment = false"
        @make-payment="makeDirectPayment"
      />
    </custom-modal>
  </main>
</template>

<script>
import PaginationComponent from '@/components/Pagination.vue'
import DashboardCard from '@/components/DashboardCard.vue'
import DirectPayment from '@/components/DirectPayment.vue'
import errorModule from '@/util/error.handle'
import { HomeIcon, XIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'

export default {
  name: 'Purchases',
  components: {
    DashboardCard,
    HomeIcon,
    PaginationComponent,
    DirectPayment,
    XIcon,
  },
  mounted() {
    this.fetchProductsByMerchant(1)
    this.getStaffList()
    this.getProductCategories()
  },
  data() {
    return {
      products: null,
      result: null,
      categoryName: null,
      notification: null,
      searchTerm: '',
      makingDirectPayment: false,
      showWalletBalance: false,
      showRewardPoints: false,
      fundingWallet: false,
      loading: false,
      url: process.env.VUE_APP_API_URL_HOST,
      categories: [],
      staffList: [],
    }
  },
  computed: {
    ...mapGetters([
      'walletBalance',
      'rewardPoints',
      'mode',
      'currentMerchant',
      'categoryId',
    ]),
    branch() {
      return JSON.parse(this.$route.query.branch)
    },
  },
  methods: {
    getImgUrl() {
      return require('@/assets/images/fallback-image.png')
    },
    toggleWalletBalance(value) {
      value ? (this.showWalletBalance = true) : (this.showWalletBalance = false)
    },
    toggleRewardPoints(value) {
      value ? (this.showRewardPoints = true) : (this.showRewardPoints = false)
    },
    async search() {
      try {
        this.categoryName = null
        const { status, data } = await this.axios.get(
          `account/products?search=${this.searchTerm}`
        )

        if (status === 200) {
          this.products = data.data.data
          this.result = data.data
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async fetchProductsByMerchant(pageNo) {
      try {
        this.searchTerm = ''

        if (pageNo === '...' || pageNo < 1) {
          return
        }

        if (this.result && pageNo > this.result.last_page) {
          return
        }

        this.loading = true

        const { status, data } = await this.axios.get(
          `/account/products?page=${pageNo}&branch=${this.currentMerchant.id}`
        )
        if (status === 200) {
          this.products = data.data.data
          this.result = data.data
          this.loading = false
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.loading = false
      }
    },
    async makeDirectPayment(payload) {
      try {
        const { status } = await this.axios.post(
          `/account/products/branches/${this.currentMerchant.id}/direct-pay`,
          payload
        )
        if (status === 200) {
          this.makingDirectPayment = false
          this.notification = {
            type: 'success',
            message: 'Payment successful',
          }
        }
      } catch (error) {
        if (error.response.status === 500) {
          this.notification = {
            type: 'error',
            message: `
            <h3>Payment failed</h3>
            <p>Please try again.</p>
            `,
          }
        } else {
          this.notification = errorModule(
            error,
            this.notification,
            this.$router,
            this.$store
          )
        }
        this.makingDirectPayment = false
      }
    },
    async getStaffList() {
      try {
        const { status, data } = await this.axios.get(
          `account/products/branches/${this.currentMerchant.id}/staff`
        )

        if (status === 200) {
          this.staffList = data.data
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.loading = false
      }
    },
    async getProductCategories() {
      try {
        this.loading = true
        const { status, data } = await this.axios.get(
          `account/products/branches/${this.currentMerchant.id}/categories`
        )

        if (status === 200) {
          this.categories = data.data
          this.loading = false
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.loading = false
      }
    },
    async getProductsByCategory(category) {
      try {
        const { status, data } = await this.axios.get(
          `account/products?category=${category.id}`
        )

        if (status === 200 && data.data.data.length) {
          this.categoryName = category.name
          this.products = data.data.data
          this.result = data.data
        } else {
          this.notification = {
            type: 'info',
            message: `No products found for ${category.name}`,
          }
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>
