<template>
  <main class="h-screen overflow-y-scroll overscroll-y-contain px-10 pb-20">
    <x-icon
      size="1.3x"
      class="
        custom-class
        mb-8
        -ml-5
        -mt-4
        text-primary-color
        cursor-pointer
        sticky
        top-0
      "
      @click="$emit('closeModal')"
    ></x-icon>
    <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
      Make Direct Payment
    </h1>
    <div class="my-3 text-sm mb-8">Make instant payments to merchants.</div>
    <div class="text-legend-gray dark:text-border-color mb-8">
      <label class="font-semibold text-sm block mb-2">Amount to send</label>
      <div
        class="
          flex
          items-center
          border border-border-color
          rounded-lg
          px-4
          py-3
        "
      >
        <div class="flex-shrink-0 pr-2">₦</div>
        <input
          v-model.number="amount"
          type="text"
          placeholder="Enter amount to pay"
          class="w-full focus:outline-none bg-transparent"
        />
      </div>
      <ValidationMessage v-if="loading && !$v.amount.required">
        <span slot="message">You must enter an amount to send</span>
      </ValidationMessage>
    </div>
    <div class="text-legend-gray dark:text-border-color">
      <label class="font-semibold text-sm mb-2 block"
        >Payment Description</label
      >
      <textarea
        v-model="description"
        class="
          border border-border-color
          rounded-md
          resize-none
          w-full
          bg-transparent
          h-20
          focus:outline-none
          p-2
        "
      ></textarea>
      <ValidationMessage v-if="loading && !$v.description.required">
        <span slot="message">Add payment description</span>
      </ValidationMessage>
    </div>
    <div class="mt-6">
      <h1>Do you want to tip Staff for good customer service?</h1>
      <section
        class="grid grid-cols-2 gap-0 my-6 uppercase font-semibold text-sm"
      >
        <div class="flex items-center" @click="tipStaff = 'no'">
          <div
            v-if="tipStaff === 'no'"
            style="width: 18px; height: 18px"
            class="bg-primary-color rounded flex items-center justify-center"
          >
            <img src="@/assets/images/check-mark.svg" />
          </div>
          <img
            v-if="tipStaff === 'yes'"
            src="@/assets/images/checkbox-unchecked.svg"
          />
          <span class="ml-2">no</span>
        </div>
        <div class="flex items-center" @click="tipStaff = 'yes'">
          <div
            v-if="tipStaff === 'yes'"
            style="width: 18px; height: 18px"
            class="bg-primary-color rounded flex items-center justify-center"
          >
            <img src="@/assets/images/check-mark.svg" />
          </div>
          <img
            v-if="tipStaff === 'no'"
            src="@/assets/images/checkbox-unchecked.svg"
          />
          <span class="ml-2">yes</span>
        </div>
      </section>
      <h1 v-if="tipStaff.toLowerCase() === 'yes'" class="mt-10 mb-6">
        Select from the registered staff
      </h1>
      <div
        v-if="tipStaff.toLowerCase() === 'yes'"
        class="text-legend-gray dark:text-border-color relative mb-8"
      >
        <label class="font-semibold text-sm mb-1">select staff</label>
        <input
          v-model="tipRecipient"
          type="text"
          readonly
          placeHolder="Select Staff to tip"
          class="
            staff-option
            w-full
            border border-border-color
            rounded-lg
            flex
            items-center
            p-3
            bg-transparent
            focus:outline-none
            capitalize
          "
          @click="staffOptions = !staffOptions"
        />
        <chevron-down-icon
          size="1.8x"
          class="
            staff-option
            custom-class
            pr-2
            text-legend-gray
            dark:text-border-color
            absolute
            right-1
            top-9
          "
        ></chevron-down-icon>
        <ul
          v-click-outside="hideDropdown"
          v-if="staffOptions"
          class="
            staff-option
            border border-gray-200
            shadow-md
            rounded-md
            bg-white
            dark:bg-dark-mode-primary
            absolute
            left-0
            top-full
            w-full
            z-50
            max-h-40
            overflow-y-scroll
            overscroll-contain
          "
        >
          <li
            v-if="staffList && !staffList.length"
            class="staff-option py-2 px-4 font-semibold text-center"
          >
            No staff found
          </li>
          <li
            v-for="(staff, index) in staffList"
            :key="index"
            class="staff-option py-2 px-4 hover:bg-gray-100 cursor-pointer"
            @click="assignSelectedStaff(staff)"
          >
            {{ staff.username }}
          </li>
        </ul>
        <ValidationMessage
          v-if="loading && tipStaff.toLowerCase() === 'yes' && !tipRecipient"
        >
          <span slot="message">Select the recipient of your tip</span>
        </ValidationMessage>
      </div>
    </div>
    <div
      v-if="tipRecipient"
      class="text-legend-gray dark:text-border-color mb-8"
    >
      <label class="font-semibold text-sm block mb-2">Amount to tip</label>
      <div
        class="
          flex
          items-center
          border border-border-color
          rounded-lg
          px-4
          py-3
        "
      >
        <div class="flex-shrink-0 pr-3">₦</div>
        <input
          v-model.number="tipAmount"
          type="text"
          placeholder="Enter amount to tip"
          class="w-full focus:outline-none bg-transparent"
        />
      </div>
      <ValidationMessage
        v-if="loading && tipStaff.toLowerCase() === 'yes' && !tipAmount"
      >
        <span slot="message">You must enter an amount to tip</span>
      </ValidationMessage>
    </div>
    <div class="relative my-8">
      <label
        class="
          text-legend-gray
          dark:text-border-color
          font-semibold
          block
          text-sm
          capitalize
          leading-relaxed
        "
        >transaction pin</label
      >
      <input
        v-model="pin"
        :type="inputType"
        placeholder="e.g legend@#20"
        class="
          text-seconadry-border
          bg-transparent
          border border-border-color
          rounded-lg
          w-full
          p-3
          focus:outline-none
        "
      />
      <img
        :src="imageURL"
        alt="toggle password visibility"
        class="absolute right-6 top-10 w-7 cursor-pointer"
        @click="togglePasswordField"
      />
      <ValidationMessage v-if="loading && !$v.pin.required">
        <span slot="message">Transaction pin is required</span>
      </ValidationMessage>
    </div>
    <custom-button @sendAction="makePayment" :loading="submitting">
      <span slot="button-text">Make Payment</span>
    </custom-button>
  </main>
</template>

<script>
import { XIcon, ChevronDownIcon } from 'vue-feather-icons'
import { required } from 'vuelidate/lib/validators'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'DirectPayment',
  props: {
    staffList: {
      type: Array,
      default: null,
    },
  },
  components: {
    XIcon,
    ChevronDownIcon,
  },
  data() {
    return {
      amount: null,
      description: null,
      pin: null,
      tipStaff: 'no',
      tipRecipient: null,
      tipAmount: null,
      staffOptions: false,
      inputType: 'password',
      loading: false,
      submitting: false,
    }
  },
  validations() {
    return {
      amount: { required },
      description: { required },
      pin: { required },
    }
  },
  computed: {
    imageURL() {
      let image

      this.inputType === 'password'
        ? (image = 'eye-icon.svg')
        : (image = 'eye-icon-active.svg')

      return this.getImgUrl(image)
    },
  },
  directives: {
    ClickOutside,
  },
  methods: {
    hideDropdown(event) {
      if (!Array.from(event.target.classList).includes('staff-option')) {
        this.staffOptions = false
      }
    },
    assignSelectedStaff(staff) {
      this.tipRecipient = staff.username
      this.staffOptions = false
    },
    getImgUrl(fileName) {
      return require(`@/assets/images/${fileName}`)
    },
    togglePasswordField() {
      if (this.inputType === 'password') {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }
    },
    makePayment() {
      if (this.submitting) {
        return
      }

      this.loading = true

      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      if (
        this.loading &&
        this.tipStaff.toLowerCase() === 'yes' &&
        !this.tipRecipient
      ) {
        return
      }

      this.submitting = true

      this.$emit('make-payment', {
        amount: this.amount,
        description: this.description,
        tip: this.tipStaff,
        staff_username: this.tipRecipient,
        tip_amount: this.tipAmount,
        pin: this.pin,
      })
    },
  },
}
</script>
