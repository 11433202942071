<template>
  <div v-if="result" class="flex items-center justify-between my-6">
    <div>
      <span class="font-bold mr-2">{{ result.from }} - {{ result.to }} </span>
      of
      <span class="font-bold ml-2">{{ result.total | moneyFormat }} </span>
      {{
        $route.name.includes('Purchase') ? 'Products' : 'Transactions'
      }}
      Showing
    </div>
    <div class="flex items-center text-legend-gray dark:text-border-color">
      <div
        class="
          arrow-left-wrapper
          border border-main-border
          rounded-full
          w-12
          h-12
          flex
          items-center
          justify-center
          cursor-pointer
        "
        @click="$emit('fetchByPage', result.current_page - 1)"
      >
        <chevron-left-icon
          size="1.1x"
          class="custom-class arrow-left"
        ></chevron-left-icon>
      </div>
      <ul class="flex">
        <li
          v-for="(link, index) in result.links"
          :key="index"
          class="mx-2 cursor-pointer"
          @click="$emit('fetchByPage', link.label)"
        >
          <span
            v-if="link.label.length < 6"
            :class="{
              'text-primary-color font-semibold border-b border-primary-color':
                link.active,
            }"
          >
            {{ link.label }}
          </span>
        </li>
      </ul>
      <div
        class="
          arrow-right-wrapper
          border border-main-border
          rounded-full
          w-12
          h-12
          flex
          items-center
          justify-center
          cursor-pointer
        "
        @click="$emit('fetchByPage', result.current_page + 1)"
      >
        <chevron-right-icon
          size="1.1x"
          class="custom-class arrow-right"
        ></chevron-right-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons'
export default {
  name: 'Pagination',
  props: {
    result: {
      type: Object,
      default: () => {},
    },
  },
  components: { ChevronLeftIcon, ChevronRightIcon },
  watch: {
    result() {
      if (this.result.current_page > 1) {
        this.addBorder('.arrow-left-wrapper', '.arrow-left')
      } else {
        this.removeBorder('.arrow-left-wrapper', '.arrow-left')
      }

      if (this.result.current_page === this.result.last_page) {
        this.removeBorder('.arrow-right-wrapper', '.arrow-right')
      } else {
        this.addBorder('.arrow-right-wrapper', '.arrow-right')
      }
    },
  },
  mounted() {
    if (this.result) {
      if (this.result.current_page > 1) {
        this.addBorder('.arrow-left-wrapper', '.arrow-left')
      } else {
        this.removeBorder('.arrow-left-wrapper', '.arrow-left')
      }

      if (this.result.current_page == this.result.last_page) {
        this.removeBorder('.arrow-right-wrapper', '.arrow-right')
      } else {
        this.addBorder('.arrow-right-wrapper', '.arrow-right')
      }
    }
  },
  methods: {
    addBorder(containerSelector, mainSelector) {
      const nodeWrapper = document.querySelector(containerSelector)
      const node = document.querySelector(mainSelector)

      nodeWrapper.classList.remove('border-main-border')
      nodeWrapper.classList.add('border-primary-color')
      nodeWrapper.classList.remove('cursor-not-allowed')
      node.classList.remove('text-main-border')
      node.classList.add('text-primary-color')
    },
    removeBorder(containerSelector, mainSelector) {
      const nodeWrapper = document.querySelector(containerSelector)
      const node = document.querySelector(mainSelector)

      if (nodeWrapper && node) {
        nodeWrapper.classList.add('border-main-border')
        nodeWrapper.classList.remove('border-primary-color')
        nodeWrapper.classList.add('cursor-not-allowed')
        node.classList.remove('text-primary-color')
        node.classList.add('text-main-border')
      }
    },
  },
}
</script>
